import React, {useEffect, useState} from 'react'
import classnames from 'classnames'
import { isEmpty } from 'lodash'
import '../form-components.css'
import {setGridAreaContent, setLiveStreamSubscribed, setUserEmail} from '../../app/App.action'
import { useStateValue } from '../../state-provider/StateProvider'
import Countdown from "../../countdown/Countdown";
import {ws} from "../../../events/ws";
import {pure} from "recompose";
import {sendToWidget} from "../../../utils/postMessages";
import {log} from '../../../utils/log'

const ReminderForm = ({
  showNotifiedMessage,
  isSubscribed,
  isCountdownFinished,
  liveStreamConnected,
}) => {
  const [{ liveStream, isWidgetMaximized, userEmail, translations, liveStreamSubscribed, }, dispatch] = useStateValue()
  const [isReminderFormHidden, hideReminderForm] = useState(isSubscribed)
  const [email, setEmail] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isSimilarEventsSelected, changeSimilarEventsCheckbox] = useState(true) // true by default
  const [isNewsletterSelected, changeNewsletterCheckbox] = useState(false) // not used for now

    useEffect(() => {
        if (userEmail) {
            setEmail(userEmail)
        }
    }, [userEmail]);

  const sendReminderForm = async e => {
    e.preventDefault()
    setIsLoading(true)
    const timezone = new Intl.DateTimeFormat().resolvedOptions().timeZone
    try {
        let pastScheduledTime = new Date(liveStream.scheduledAt * 1000) < new Date()
        ws.sendLivestreamSubscription(
            liveStream.id,
            email,
            timezone,
            !pastScheduledTime, // only subscribe current live stream if not past scheduled time
            isSimilarEventsSelected || pastScheduledTime, // subscribe for similar events if checkbox selected or past scheduled time
            isNewsletterSelected,
        )
        dispatch(setUserEmail({ userEmail: email }))
        sendToWidget({ type: 'update-user-email', value: email })
        dispatch(setLiveStreamSubscribed(true))

        setEmail('')
        changeSimilarEventsCheckbox(false)
        changeNewsletterCheckbox(false)

        setTimeout(() => {
          dispatch(setGridAreaContent('Grid'))
        }, 3000)
        hideReminderForm(true);
        setIsLoading(false)
    } catch (err) {
      log(err)
      setIsLoading(false)
    }
  }
  return (
    <>
      <div className="reminder-bg">
        <img src={liveStream.poster} />
      </div>
      <div className="reminder-content">
        {/*<div className={"blur"}>*/}
          <div className={"countdown-root"}>
            <Countdown
                liveStreamConnected={liveStreamConnected}
                isWidgetMaximized={isWidgetMaximized}
                liveStream={liveStream}
                showNotifiedMessage={showNotifiedMessage}
                isSubscribed={isReminderFormHidden}
            />
          </div>
          
          <form onSubmit={sendReminderForm} className={classnames("reminder-container", {show: !isReminderFormHidden })}>
          {isCountdownFinished && <label htmlFor="email">{translations['SubscriptionFormInputEmailLabelNextEvents']}</label>}
          <input
                    tabIndex={-1}
                    className="show"
                    type="email"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    placeholder={translations['SubscriptionFormInputEmailLabel']}
                    id="email"
                    name="email"
                    disabled={isLoading}
                    required
                ></input>
                <button
                    disabled={isLoading}
                    className="remind-me"
                    type="submit"
                    // style={extendedStyles}
                >
                    {translations['SubscriptionFormButtonSubscribeLabel']}
                </button>
                <div className="form-group">
                  <input
                      tabIndex={-1}
                      checked={isSimilarEventsSelected}
                      onChange={() =>
                          changeSimilarEventsCheckbox(!isSimilarEventsSelected)
                      }
                      type="checkbox"
                      id="similarEvents"
                  />
                  <label htmlFor="similarEvents">{translations['SubscriptionFormCheckboxSimilarEventsLabel']}</label>
                </div>
              </form>

        {/*</div>*/}

      </div>
    </>
  )
}

export default pure(ReminderForm)
