import {IS_DEBUG} from "./Constants";


export const log = (text: string, error?: any) => {
  if (IS_DEBUG && console && console.log) {

    let msg = text;
    if (error?.message) {
      msg += `, errorMessage='${error.message}'`
    }
    if (error?.stack) {
      msg += `, errorStack='${error.stack}'`
    }
    console.log(`Contester LOG: ${msg}`)
  }
}
