import {
  experienceEventLiked,
  experienceEventShared,
  navigateCard,
  setBrandFollowing,
  setGridAreaContent,
  setLiveChatMode,
  setLiveStreamSubscribed,
  setLiveStreamViewers,
  setSequenceLiveData,
  setSpHash,
  setSurf,
} from '../modules/app/App.action'
import {
  setLiveChatData,
  setLiveChatHistoryHash,
  addMessageToLiveChat,
  addMessageReplyToLiveChat,
  addMessageReactionToLiveChat,
  setSessionEstablished,
  replacePendingMessageToLiveChat,
  setShareUrl
} from '../modules/chat/Chat.action'

import { localStorageAvailable } from '../utils/localStorage'
import { isEqual } from 'lodash'
import {LiveChatUserProfileDataObject} from "../api/types";
import {sendToWidget} from "../utils/postMessages";
import {IS_STAGING} from "../utils/Constants";
import {log} from "../utils/log";

enum MessageType {
  ERROR = 'ERROR',
  DEBUG = 'DEBUG',
  EVENT_INTERACTION = 'EVENT_INTERACTION',
  STATE_METRICS = 'STATE_METRICS',
  PING = 'PING',
  PAGE_LOAD = 'PAGE_LOAD',
  PAGE_LOAD_FAILED = 'PAGE_LOAD_FAILED',
  LIVECHAT_JOIN = 'LIVECHAT_JOIN',
  LIVECHAT_SEND_MESSAGE = 'LIVECHAT_SEND_MESSAGE',
  LIVESTREAM_SUBSCRIPTION = 'LIVESTREAM_SUBSCRIPTION',
  LIVESTREAM_PROGRAM_DATE_TIME = 'LIVESTREAM_PROGRAM_DATE_TIME',

  FOLLOW_REQUEST = 'FOLLOW_REQUEST',
}

export enum EventInteractionType {
  WIDGET_MINIMIZED = 'WIDGET_MINIMIZED',
  WIDGET_MAXIMIZED = 'WIDGET_MAXIMIZED',
  WIDGET_NOTIFICATION = 'WIDGET_NOTIFICATION',
  WIDGET_CLOSED = 'WIDGET_CLOSED',

  // Send cardId for the next events, i.e. sendEvent(EventInteractionType.HEADER_VIDEO_UNMUTED, cardId);
  HEADER_VIDEO_UNMUTED = 'HEADER_VIDEO_UNMUTED',
  HEADER_VIDEO_MUTED = 'HEADER_VIDEO_MUTED',
  HEADER_VIDEO_PAUSED = 'HEADER_VIDEO_PAUSED',
  HEADER_VIDEO_UNPAUSED = 'HEADER_VIDEO_UNPAUSED',
  HEADER_VIDEO_MAXIMIZED = 'HEADER_VIDEO_MAXIMIZED',
  HEADER_VIDEO_MINIMIZED = 'HEADER_VIDEO_MINIMIZED',

  CARD_DISPLAYED = 'CARD_DISPLAYED',

  CARD_DIRECT_LINK_CLICKED = 'CARD_DIRECT_LINK_CLICKED',

  SOCIAL_LIKE = 'SOCIAL_LIKE',
  SOCIAL_SHARE = 'SOCIAL_SHARE',
}

interface Message {
  type: MessageType
  payload: any
}

enum ServerMessageType {
  ERROR = 'ERROR',
  DEBUG = 'DEBUG',
  REDIRECT = 'REDIRECT',
  SETTINGS = 'SETTINGS',
  REFRESH = 'REFRESH',
  RELOAD = 'RELOAD',
  LIVE_CHAT_UPDATED = 'LIVE_CHAT_UPDATED',
  LIVE_CHAT_USER_JOINED = 'LIVE_CHAT_USER_JOINED',
  LIVE_CHAT_MESSAGE_SENT = 'LIVE_CHAT_MESSAGE_SENT',
  LIVE_CHAT_MESSAGE_REPLY_SENT = 'LIVE_CHAT_MESSAGE_REPLY_SENT',
  LIVE_CHAT_MESSAGE_REACTION_SENT = 'LIVE_CHAT_MESSAGE_REACTION_SENT',

  LIVE_STREAM_VIEWERS_UPDATED = 'LIVE_STREAM_VIEWERS_UPDATED',

  EXPERIENCE_EVENT_LIKED = 'EXPERIENCE_EVENT_LIKED',
  EXPERIENCE_EVENT_SHARED = 'EXPERIENCE_EVENT_SHARED',

  SURF = 'SURF',

  AUTOPILOT_CARD_FOCUS = 'AUTOPILOT_CARD_FOCUS',
}

interface ServerMessage {
  type: ServerMessageType
  payload: any
}

interface ServerMessageRedirect {
  redirect: string
}

interface ServerMessageSettings {
  lfs: number // log frequency seconds
  ffs: number // flush frequency seconds
  rcs: number // reconnect frequency seconds
  pfs: number // ping frequency seconds
  lcm: LiveChatMode
  sm: SurfMode
  hash: string
  liveData: ServerSettingsLiveData
  sessionData: ServerSettingsSessionLiveData
  liveChats: LiveChatDataObject[],
  feState: FEState,
}

interface FEState {
  brandFollowing: boolean,
  creatorFollowing: boolean,
  liveStreamSubscribed: boolean,
}

interface ServerMessageSurf {
  location: string
  surf: boolean
}

interface ServerMessageAutopilodCardFocus {
  experienceId: string
  cardId: string
}

enum SurfMode {
  Auto = 'Auto',
  Manual = 'Manual',
}

enum LiveChatMode {
  RT = 'RT', // real-time
  NRT = 'NRT', // near-real-time
}

export interface LiveStreamViewersUpdated {
  experiences: string[],
  viewers: number,
}

export interface ExperienceEventLiked {
  sessionId?: string,
  experienceId: string,
  cardId: string,
  likes: number,
}

export interface ExperienceEventShared {
  sessionId?: string,
  experienceId: string,
  cardId: string,
  shares: number,
}

export interface LiveChatUpdated {
  liveChatId: string,
  referenceId: string,
  experienceIds: string[],
  hash: string,
}

export interface LiveChatUserJoined {
  sessionId?: string,
  liveChatId: string,
  referenceId: string,
  experienceIds: string[],
  createdAt: string,
  user: LiveChatUserProfileDataObject,
}

export interface LiveChatMessageSent {
  sessionId?: string,
  liveChatId: string,
  referenceId: string,
  experienceIds: string[],
  id: string,
  createdAt: string,
  message: string,
  user: LiveChatUserProfileDataObject,
}

export interface LiveChatMessageReplySent {
  sessionId?: string,
  liveChatId: string,
  referenceId: string,
  experienceIds: string[],
  replyMessageId: string,
  replyMessageSessionId?: string,
  id: string,
  createdAt: string,
  message: string,
  user: LiveChatUserProfileDataObject,
}

export interface LiveChatMessageReactionSent {
  sessionId?: string,
  liveChatId: string,
  referenceId: string,
  experienceIds: string[],
  messageId: string,
  reactionCode: string,
  createdAt: string,
  user: LiveChatUserProfileDataObject,
}

export interface LiveChatDataObject {
  liveChatId: string,
  referenceId: string,
  hash: string,
  profile: LiveChatUserProfileDataObject,
}

export interface ServerSettingsSessionLiveData {
  id: string
  creditedId: string
  queryString: string | null
  languageCode: string
}

export interface ServerSettingsLiveData {
  shareUrl: string
  liveStreamViewers: number
  cards: ServerSettingsLiveDataCard[]
}

export interface ServerSettingsLiveDataCard {
  id: string
  liked: boolean
  shared: boolean
  likes: number
  shares: number
  views: number
}

interface ServerMessageRefresh {
  experienceId: string
  cardId: string | null
  hash: string
}

export enum MetricsType {
  videoMuted = 'videoMuted',
  videoUnmuted = 'videoUnmuted',
  videoPaused = 'videoPaused',
  videoPlaying = 'videoPlaying',
  liveStreamPlaying = 'liveStreamPlaying',
}

interface MetricsObject {
  cardId: string
  map: Map<MetricsType, number>
}

/**
 * localStorage global state
 */
interface ContesterState {
  credited: ContesterStateCredited[] | null
  experiences: ContesterStateExperience[] | null
}

interface ContesterStateExperience {
  id: string
  followed: boolean
}

interface ContesterStateCredited {
  id: string
  host: string
  timestamp: number
  experienceId: string
}

class WS {
  private dispatch: any
  private history: any
  private location: string | null = null
  private referer: string | null = null
  private widgetEmbedded: boolean | null = null

  private socket: WebSocket | null = null
  private currentExperienceId: string | null = null

  private cardUuid: string | null = null

  private flushSeconds: number | null = null
  private flushIntervalHandler: NodeJS.Timeout | null = null

  private logSeconds: number | null = null
  private logIntervalHandler: NodeJS.Timeout | null = null

  private pingSeconds: number | null = null
  private pingIntervalHandler: NodeJS.Timeout | null = null

  private reconnectSeconds: number | null = null

  private surfMode: SurfMode | null = null

  private metricsMap: Map<MetricsType, () => boolean> = new Map<
    MetricsType,
    () => boolean
  >()
  private metricsSeconds: Array<MetricsObject> = new Array<MetricsObject>()

  private wsPath = IS_STAGING
    ? 'wss://staging-api.contester.net/api/public/ws'
    : 'wss://api.contester.net/api/public/ws'

  private buffer: Message[] = []

  private flushMetrics(): void {
    const payloads: any[] = []
    this.metricsSeconds.forEach(metrics => {
      // it may seem counter-intuitive to avoid using a type-safe interface for message
      // but actually by doing this we're not including properties which do not have metrics seconds associated with them.
      // that way we save bandwidth for users

      const payload: any = {}
      metrics.map.forEach((value, key) => {
        payload[key] = value
      })
      payload['cardId'] = metrics.cardId

      payloads.push(payload)
    })
    if (payloads.length > 0) {
      this.send(MessageType.STATE_METRICS, payloads)
    }
    this.metricsSeconds = []
  }

  private logMetrics(): void {
    const metricsSeconds: Map<MetricsType, number> = new Map<
      MetricsType,
      number
    >()
    const logSeconds = this.logSeconds!!
    this.metricsMap.forEach((value, key) => {
      const trueState = value()
      // this.log("log metrics " + key + " = " + trueState)

      if (trueState) {
        metricsSeconds.set(key, logSeconds + (metricsSeconds.get(key) ?? 0))
      }
    })


    /**
     * If cardUuid is unassigned, it means data isn't fetched from API either. So something is very off and we shouldn't even bother logging stuff
     */
    if (metricsSeconds.size > 0 && Boolean(this.cardUuid)) {

      let entry = {
        cardId: this.cardUuid!!,
        map: metricsSeconds,
      }

      // the following code merges two identical metrics objects into one.
      // Most of the time if user is not interacting with widget, state metrics will include two identical objects per ws message

      const merge = () => {
        let existingArray = this.metricsSeconds.filter(it => isEqual(it, entry))
        if (existingArray && existingArray.length > 0) {
          existingArray.forEach(existing => {
            existing.map.forEach((value, key) => {
              entry.map.set(key, entry.map.get(key)!! + value)
            })
            this.metricsSeconds = this.metricsSeconds.filter(it => it !== existing)
          })
        }
      }

      // we log at 2 second interval, report up to 64 second intervals.
      // merge at least into 2*2*2*2*2=32 to avoid spamming API with traffic
      for (let i = 0; i < 5; i++) {
        merge()
      }

      this.metricsSeconds.push(entry)
    }
  }

  private ping(): void {
    this.send(MessageType.PING, {})
  }

  clearMetrics(type: MetricsType): void {
    this.metricsMap.delete(type)
  }

  registerMetrics(type: MetricsType, provider: () => boolean): void {
    this.metricsMap.set(type, provider)
  }

  close() {
    log('closing and flushing current messages')
    this.removeHandlers()
    this.flushMetrics()
  }

  private removeHandlers() {
    if (this.flushIntervalHandler != null) {
      log('cancelling existing flush interval handler')
      clearInterval(this.flushIntervalHandler)
      this.flushIntervalHandler = null
    }
    if (this.logIntervalHandler != null) {
      log('cancelling existing log interval handler')
      clearInterval(this.logIntervalHandler)
      this.logIntervalHandler = null
    }
    if (this.pingIntervalHandler != null) {
      log('cancelling existing ping interval handler')
      clearInterval(this.pingIntervalHandler)
      this.pingIntervalHandler = null
    }
  }

  private onServerMessage(message: ServerMessage): void {
    log('onServerMessage for type ' + message.type)

    if (message.type === ServerMessageType.REDIRECT) {
      const redirect: ServerMessageRedirect = message.payload
      if (this.widgetEmbedded) {
        sendToWidget(
          { type: 'redirect', value: { redirect: redirect.redirect } }
        )
      } else {
        window.location.href = redirect.redirect
      }
    } else if (message.type === ServerMessageType.SETTINGS) {
      // server sends settings on how often to log metrics and how often to flush it
      const settings: ServerMessageSettings = message.payload
      this.flushSeconds = settings.ffs ?? 2
      this.logSeconds = settings.lfs ?? 1
      this.pingSeconds = settings.pfs ?? 30
      this.reconnectSeconds = settings.rcs ?? 5
      this.surfMode = settings.sm

      this.assignCreditedSessionId(settings.sessionData.creditedId)
      this.dispatch(setSpHash({ hash: settings.hash, postToWidget: true }))

      this.dispatch(setLiveChatMode({ liveChatMode: settings.lcm }))

      this.dispatch(
        setSequenceLiveData({
          liveData: settings.liveData,
          sessionData: settings.sessionData,
        }),
      )

      this.dispatch(setShareUrl({ shareUrl: settings.liveData.shareUrl }))

      this.dispatch(
        setLiveChatData({
          liveChats: settings.liveChats,
        }),
      )

      this.dispatch(setLiveStreamSubscribed(settings.feState.liveStreamSubscribed))
      this.dispatch(setBrandFollowing(settings.feState.brandFollowing || settings.feState.creatorFollowing))

      this.removeHandlers()

      log('flush seconds ' + this.flushSeconds)
      log('log seconds ' + this.logSeconds)
      log('ping seconds ' + this.pingSeconds)
      this.flushIntervalHandler = setInterval(() => {
        this.flushMetrics()
      }, this.flushSeconds * 1000)
      this.logIntervalHandler = setInterval(() => {
        this.logMetrics()
      }, this.logSeconds * 1000)
      this.pingIntervalHandler = setInterval(() => {
        this.ping()
      }, this.pingSeconds * 1000)

      this.dispatch(setSessionEstablished(true))
    } else if (message.type === ServerMessageType.REFRESH) {
      const payload: ServerMessageRefresh = message.payload
      this.cardUuid = payload.cardId
      this.currentExperienceId = payload.experienceId
      this.dispatch(setSpHash({ hash: payload.hash }))
    } else if (message.type === ServerMessageType.RELOAD) {

      if (this.widgetEmbedded) {
        sendToWidget(
          { type: 'reload', value: {} },
        )
      } else {
        window.location.reload()
      }
    } else if (message.type === ServerMessageType.LIVE_CHAT_MESSAGE_REACTION_SENT) {
      const payload: LiveChatMessageReactionSent = message.payload
      if (!payload.sessionId) {
        this.dispatch(addMessageReactionToLiveChat(payload))
      }
    } else if (message.type === ServerMessageType.LIVE_CHAT_MESSAGE_REPLY_SENT) {
      const payload: LiveChatMessageReplySent = message.payload
      if (!payload.replyMessageSessionId) {

        this.dispatch(addMessageReplyToLiveChat(payload))
      } else {
        // this is the place to put a flashy notification to the user.
        // if payload.sessionId is not undefined, it means that this exact user received a reply to one of their messages.
        // we should focus their attention on the reply.
        this.dispatch(addMessageReplyToLiveChat(payload))
        this.dispatch(setGridAreaContent('LiveChat'))
      }
    } else if (message.type === ServerMessageType.LIVE_CHAT_MESSAGE_SENT) {
      const payload: LiveChatMessageSent = message.payload
      if (!payload.sessionId) {
        this.dispatch(addMessageToLiveChat(payload))
      } else {
        this.dispatch(replacePendingMessageToLiveChat(payload))
      }
    } else if (message.type === ServerMessageType.LIVE_CHAT_USER_JOINED) {
      const payload: LiveChatUserJoined = message.payload
      if (!payload.sessionId) {
        this.dispatch(addMessageToLiveChat({
          id: payload.user.id,
          sessionId: payload.sessionId,
          referenceId: payload.referenceId,
          experienceIds: payload.experienceIds,
          liveChatId: payload.liveChatId,
          createdAt: payload.createdAt,
          user: payload.user,
          message: `joined`
        }))
      }
    } else if (message.type === ServerMessageType.LIVE_CHAT_UPDATED) {
      const payload: LiveChatUpdated = message.payload
      this.dispatch(setLiveChatHistoryHash({ liveChatId: payload.liveChatId, hash: payload.hash }))
    } else if (message.type === ServerMessageType.EXPERIENCE_EVENT_SHARED) {
      const payload: ExperienceEventShared = message.payload
      this.dispatch(experienceEventShared(payload))
    } else if (message.type === ServerMessageType.EXPERIENCE_EVENT_LIKED) {
      const payload: ExperienceEventLiked = message.payload
      this.dispatch(experienceEventLiked(payload))
    } else if (message.type === ServerMessageType.LIVE_STREAM_VIEWERS_UPDATED) {
      const payload: LiveStreamViewersUpdated = message.payload
      this.dispatch(setLiveStreamViewers({ viewers: payload.viewers }))
    } else if (message.type === ServerMessageType.SURF) {
      const payload: ServerMessageSurf = message.payload
      if (payload.surf) {
        this.dispatch(setSurf({ surf: payload.surf, mode: this.surfMode }))
      }
    } else if (message.type === ServerMessageType.AUTOPILOT_CARD_FOCUS) {
      const payload: ServerMessageAutopilodCardFocus = message.payload
      this.dispatch(navigateCard(payload.cardId, this.history, "System"))
    }
  }

  private onWebSocketMessage(event: MessageEvent): void {
    // log(`WS data received from server: ${event.data}`)
    try {
      this.onServerMessage(JSON.parse(event.data))
    } catch (e) {
      log('Error handling server message: ' + e)
    }
  }

  private initSocket(s: WebSocket): void {
    let buffer = this.buffer
    s.onopen = function (e) {
      log(`WS ready`)
      while (buffer.length > 0) {
        let message = buffer.pop()!
        log(`WS clearing out buffered message '${message.type}'`)
        s.send(JSON.stringify(message))
      }
    }

    s.onmessage = (event: MessageEvent) => {
      this.onWebSocketMessage(event)
    }

    s.onclose = (event) => {
      if (event.wasClean) {
        log(
          `WS connection closed cleanly, code=${event.code} reason=${event.reason}`,
        )
      } else {
        log('WS connection died')
        this.socket = null
        // give X seconds of breathing room in case WS gets auto-dropped on backend
        setTimeout(() => {
          this.init(this.currentExperienceId!!, this.dispatch, this.history, this.location!!, this.referer!!, this.widgetEmbedded!!)
        }, (this.reconnectSeconds ?? 5) * 1000)
      }
    }

    s.onerror = function (error) {
      log(`[error] ${error}`)
    }
  }

  private send(messageType: MessageType, payload: any): void {
    if (this.socket != null && this.currentExperienceId != null) {
      let message: Message = {
        type: messageType,
        payload: payload,
      }

      if (this.socket.readyState === 1) {
        // 1: ready
        // send immediately
        log(`WS sending message '${message.type}'`)
        this.socket.send(JSON.stringify(message))
      } else {
        log(`WS not ready, adding message '${message.type}' to buffer`)
        // put to buffer to send out during onopen event
        this.buffer.push(message)
      }
    }
  }

  sendLivestreamSubscription(
    liveStreamId: string,
    email: string,
    timezone: string,
    notifyLiveStream: boolean,
    notifySimilarEvents: boolean,
    notifyUpdates: boolean,
  ): void {
    this.send(MessageType.LIVESTREAM_SUBSCRIPTION, {
      liveStreamId: liveStreamId,
      email: email,
      timezone: timezone,
      notifyLiveStream: notifyLiveStream,
      notifySimilarEvents: notifySimilarEvents,
      notifyUpdates: notifyUpdates,
    })
  }

  /**
   * Calling this function also sets followed flag in the state object.
   * @param email
   * @param timezone
   */
  sendFollowRequest(
    email: string,
    timezone: string,
  ): void {
    this.send(MessageType.FOLLOW_REQUEST, {
      email: email,
      timezone: timezone,
    })
    this.setFollowed(this.currentExperienceId!!)
  }

  sendProgramDateTime(pdt: Date): void {
    this.send(MessageType.LIVESTREAM_PROGRAM_DATE_TIME, {
      userTimestampMillis: new Date().getTime(),
      programDateTimeMillis: pdt.getTime(),
      cardId: this.cardUuid!!,
    })
  }

  sendJoinLiveChat(
    organizationId: string,
    referenceId: string,
    name: string,
    email: string,
    timezone: string,
  ): void {
    this.send(MessageType.LIVECHAT_JOIN, {
      organizationId: organizationId,
      referenceId: referenceId,
      name: name,
      email: email,
      timezone: timezone,
    })
  }

  sendLiveChatMessage(
    organizationId: string,
    referenceId: string,
    message: string,
  ): void {
    this.send(MessageType.LIVECHAT_SEND_MESSAGE, {
      organizationId: organizationId,
      referenceId: referenceId,
      message: message,
    })
  }

  sendPageLoad(
    url: string,
  ): void {
    this.send(MessageType.PAGE_LOAD, {
      url: url,
    })
  }

  sendPageLoadFailed(
      url: string,
  ): void {
    this.send(MessageType.PAGE_LOAD_FAILED, {
      from: url,
    })
  }

  sendEvent(
    type: EventInteractionType,
    cardId: string | null = this.cardUuid,
  ): void {
    log(`WS: ${type}: ${this.cardUuid}`)
    // for (var i = 0; i < 10000; i++) {
    this.send(MessageType.EVENT_INTERACTION, {
      type: type,
      cardId: cardId,
    })
    // }
  }

  setCard(cardUuid: string): void {
    if (this.cardUuid !== cardUuid) {
      log('set card ' + cardUuid)
      this.cardUuid = cardUuid
      this.sendEvent(EventInteractionType.CARD_DISPLAYED)
    }
  }

  daysBetween(date1: Date, date2: Date) {
    const ONE_DAY = 1000 * 60 * 60 * 24
    const differenceMs = Math.abs(date1.getTime() - date2.getTime())
    return Math.floor(differenceMs / ONE_DAY)
  }

  private getGlobalState(): ContesterState {
    let defaultState =
      {
        credited: [],
        experiences: [],
      }
    let stateString = localStorage.getItem('contester_state')
    if (stateString == null) {
      return defaultState
    } else {
      let state
      try {
        state = JSON.parse(stateString) as ContesterState
      } catch (e) {
        state = defaultState
      }
      return state
    }
  }

  /**
   * Use this to determine whether follow feature should be enabled on a widget
   * @param experienceId
   */
  isFollowed(experienceId: string) {

    if (localStorageAvailable) {
      let state = this.getGlobalState()
      if (!state.experiences || state.experiences.constructor !== Array) {
        state.experiences = []
      }
      let match = state.experiences.findIndex(e => e.id == experienceId)
      if (match == -1) {
        return false
      } else {
        return state.experiences[match].followed
      }
    } else {
      return false
    }
  }

  private setFollowed(experienceId: string) {
    if (localStorageAvailable) {
      let state = this.getGlobalState()

      if (!state.experiences || state.experiences.constructor !== Array) {
        state.experiences = []
      }

      let match = state.experiences.findIndex(e => e.id == experienceId)
      if (match == -1) {
        state.experiences.push({
          id: experienceId,
          followed: true,
        })
      } else {
        let experienceState = state.experiences[match]
        experienceState.followed = true
      }
      localStorage.setItem('contester_state', JSON.stringify(state))
    }
  }

  /**
   * WS Settings message contains session ID.
   * First time session is established, we save the ID in localStorage with 10 days expiration.
   * Every time we establish a websocket connection, we pass that initial session ID as a parameter.
   * That way each subsequent session created in backend will contain a reference to initial original session ID.
   * This is necessary for proper sales tracking.
   *
   * @param sessionId
   * @private
   */
  private assignCreditedSessionId(sessionId: string) {
    if (localStorageAvailable) {
      let state = this.getGlobalState()

      if (!state.credited || state.credited.constructor !== Array) {
        state.credited = []
      }
      let host = new URL(this.location!!).host
      let match = state.credited.findIndex(p => p.host === host && p.experienceId == this.currentExperienceId) ?? null

      if (match == -1) {
        // not found, assign credited session id
        state.credited.push({
          id: sessionId,
          host: host,
          timestamp: new Date().getTime(),
          experienceId: this.currentExperienceId!!,
        })
        localStorage.setItem('contester_state', JSON.stringify(state))
      } else {
        // match exists, do nothing
      }
    }
  }

  private getCreditedSessionId(experienceId: string): string | null {
    if (localStorageAvailable) {
      let state = this.getGlobalState()
      let host = new URL(this.location!!).host
      if (state.credited && state.credited.constructor === Array) {
        let match = state.credited.find(p => p.host === host && p.experienceId === experienceId) ?? null
        if (match !== null) {
          return match.id
        }
      }
    }
    return null
  }

  init(
    experienceId: string,
    dispatch: any,
    history: any,
    widgetLocation: string,
    widgetLocationReferer: string,
    isWidgetEmbedded: boolean,
  ): void {
    try {
      // pass on query params to ws creation to correctly establish session
      let wsPathWithParams: string
      wsPathWithParams =
        this.wsPath + `?contester_experience_id=${experienceId}&contester_location=${btoa(widgetLocation)}`
      if (widgetLocation !== widgetLocationReferer) {
        wsPathWithParams += `&contester_referer=${btoa(widgetLocationReferer)}`
      }

      this.location = widgetLocation
      // if credited session id is available, append it to ws connection query string
      let sessionId = this.getCreditedSessionId(experienceId)
      if (sessionId != null) {
        wsPathWithParams += '&csi=' + sessionId
      }

      this.dispatch = dispatch
      this.history = history
      this.widgetEmbedded = isWidgetEmbedded

      if (this.socket == null && experienceId == null) {
        // do nothing
      }
      if (this.socket == null && experienceId != null) {
        // initialize for the first time
        this.socket = new WebSocket(wsPathWithParams)
        this.initSocket(this.socket)
        this.currentExperienceId = experienceId
      } else if (this.socket != null && experienceId == null) {
        // do nothing
      } else if (
        this.socket != null &&
        experienceId != null &&
        this.currentExperienceId !== experienceId
      ) {
        // replace socket
        this.socket.close(1000)
        this.socket = new WebSocket(wsPathWithParams)
        this.currentExperienceId = experienceId
        this.initSocket(this.socket)
      }
    } catch (e) {
      log('error initializing websocket connection ' + e)
    }
  }
}

export const ws = new WS()
